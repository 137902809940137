:root {
  --primary-11: #02090B;
  --primary-10: #221521;
  --primary-9: #49233A;
  --primary-8: #78365A;
  --primary-7: #933F6D;
  --primary-6: #B84E86;
  --primary-5: #C46B9A;
  --primary-4: #D08AAF;
  --primary-3: #DCA8C4;
  --primary-2: #E9C9DA;
  --primary-1: #F6E9F0;


  --primary-color: var(--primary-6); // color used in main actions

  --text-color: black;
  --input-focus-shadow-color: #2e3233; // outer shadow of input on focus - GIVE 20% ALPHA

  --background-color: #f3f3f3;

  --menu-text-color: black;
  --menu-item-active-bg: #d5d5d5; // color of the side menu active ite
  --menu-bg: #f4f9fa; // color of the background of the menu
  --menu-sub-bg: #ffffff; // color of the background of the submenu

  --login-background-color: var(--primary-10);

  --header-logo-background: var(--primary-9);
  --header-background: var(--primary-color);
  --header-text-color: #fff;

  --footer-text-color: #fff;
  --footer-color: var(--primary-5);
}

@primary-11: #02090B;
@primary-10: #221521;
@primary-9: #49233A;
@primary-8: #78365A;
@primary-7: #933F6D;
@primary-6: #B84E86;
@primary-5: #C46B9A;
@primary-4: #D08AAF;
@primary-3: #DCA8C4;
@primary-2: #E9C9DA;
@primary-1: #F6E9F0;

@primary-color: #B84E86;
@component-background: #ffffff;
@background-color-base: #ffffff;